import axios from "axios";
import ImagesLoaded from "imagesloaded";
import Masonry from "masonry-layout";
import { DATA } from "./data";
import gsap from "gsap";

export default class PG_Gallery {
    constructor() {
        this.initItems(DATA);
    }

    initItems(data) {
        this.dt = 0;
        const container = document.querySelector(".masonry");

        const that = this;
        data.map((d, i) => {
            const thumb = document.createElement("a");
            thumb.classList.add("masonry-item");
            thumb.innerHTML = `<img src=${d.urls.regular} />`;
            container.appendChild(thumb);
            ImagesLoaded(thumb.querySelector("img")).on("progress", function (a) {
                gsap.delayedCall(that.dt, () => {
                    gsap.to(a.elements[0], { opacity: 1, duration: 0.4, ease: "power1.inOut" });
                    that.msnry.layout();
                });

                that.dt += 0.08;
            });
        });

        this.masonry();
    }

    masonry() {
        const msnry = new Masonry(".masonry", {
            itemSelector: ".masonry-item",
            columnWidth: ".grid-sizer",
            percentPosition: true,
            transitionDuration: "0.4s",
            gutter: ".gutter-sizer", // gutter: 12,
        });
        this.msnry = msnry;
    }
}
