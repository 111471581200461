import gsap from "gsap";

export function openAccordion(accordion) {
    const content = accordion.querySelector(".accordion-content");
    if (accordion) {
        accordion.setAttribute("data-open", true);
    }

    const tr = accordion.hasAttribute("data-fade");

    const completeCallback = () => {
        const _closeEvent = (e) => {
            if (e.target.tagName !== "BUTTON") {
                closeAccordion(accordion);
                document.removeEventListener("click", _closeEvent);
            }
        };
        document.addEventListener("click", _closeEvent);
    };

    if (tr) {
        gsap.set(content, { display: "flex" });
        gsap.to(content, {
            autoAlpha: 1,
            duration: 0.25,
            ease: "power1.inOut",
            onComplete: () => completeCallback(),
        });
    } else {
        gsap.to(content, {
            height: "auto",
            duration: 0.55,
            ease: "power1.inOut",
        });
    }
    if (accordion.querySelector(".line-ver")) {
        gsap.to(accordion.querySelector(".line-ver"), {
            rotate: 90,
            duration: 0.3,
            ease: "power2.out",
        });
    }
}

export function closeAccordion(accordion) {
    const content = accordion.querySelector(".accordion-content");
    const tr = accordion.hasAttribute("data-fade");

    if (tr) {
        accordion.setAttribute("data-open", false);
        gsap.to(content, {
            autoAlpha: 0,
            duration: 0.25,
            ease: "power1.inOut",
            onComplete: () => {
                gsap.set(content, { display: "none" });
            },
        });
    }

    if (accordion.querySelector(".line-ver")) {
        accordion.setAttribute("data-open", false);
        gsap.to(content, {
            height: 0,
            duration: 0.4,
            ease: "power1.inOut",
            onComplete: () => {},
        });
        gsap.to(accordion.querySelector(".line-ver"), {
            rotate: 0,
            duration: 0.25,
            ease: "power2.out",
        });
    }
}

export function toggleAccordion(accordion) {
    const isOpen = accordion.getAttribute("data-open") == "true";

    if (!isOpen) {
        if (document.querySelector('.accordion-container[data-open="true"]')) {
            closeAccordion(document.querySelector('.accordion-container[data-open="true"]'));
        }
        openAccordion(accordion);
    } else {
        closeAccordion(accordion);
    }
}
export function toast(msg) {
    if (msg) {
        document.querySelector(".toast span").innerHTML = msg;
    }
    gsap.to(document.querySelector(".toast"), {
        opacity: 1,
        duration: 0.4,
        ease: "power1.inOut",
        onComplete: () => {
            gsap.to(document.querySelector(".toast"), {
                opacity: 0,
                duration: 0.3,
                ease: "power1.inOut",
                delay: 1,
            });
        },
    });
}

export function copyClipboard(button) {
    if (window.navigator.clipboard) {
        window.navigator.clipboard.writeText(button.getAttribute("data-value")).then(() => {
            toast("링크가 복사되었습니다");
        });
    }
}
