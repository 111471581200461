import gsap from "gsap";
import axios from "axios";
export default class ButtonBracket {
    constructor(props) {
        this.props = props;

        this.rectRefs = null;

        this.isMobile = document.body.classList.contains("view-mobile");
        this.v = 0;
        this.widthRef = 2;

        this.tlEnter = [];
        this.tlLeave = [];

        if (this.props.button) {
            this.bindEvent(this.props.button);
        } else {
            this.createLink();
        }

        this.init();
    }

    init() {
        gsap.set(this.rectRefs, { "--width": `${this.widthRef}px` });
    }
    transitionEnter() {
        const b = { width: this.v };
        this.tlEnter = gsap.timeline();
        this.tlEnter.to(b, {
            width: 12,
            duration: 0.3,
            ease: "power2.out",

            onUpdate: () => {
                this.v = b.width;
                this.rectRefs.forEach((c) => {
                    gsap.set(c, { delay: 0, "--width": `${b.width}px` });
                });
            },
        });
    }

    transitionLeave() {
        const b = { width: this.v };
        this.tlLeave = gsap.timeline();
        this.tlLeave.to(b, {
            width: this.widthRef,
            duration: 0.4,
            ease: "power2.out",
            onUpdate: () => {
                this.v = b.width;
                this.rectRefs.forEach((c) => {
                    gsap.set(c, { delay: 0, "--width": `${b.width}px` });
                });
            },
        });
    }

    createLink() {
        const { link, text, textKo, content } = this.props;

        const _link = document.createElement("a");
        _link.classList.add("button-bracket");

        if (link) {
            _link.href = link;
        }
        _link.innerHTML = `
            <span class="icon-bracket"></span>
            <span class="rect"></span>
            <span class="row">
                <span class="text">${text}</span>
                <span class="rect"></span>
                <span class="icon-arrow"></span>
            </span>
            <span class="rect"></span>
            <span class="icon-bracket reverse"></span>
        `;
        if (textKo) {
            _link.querySelector(".text").classList.add("ko");
        }

        this.bindEvent(_link);

        content.appendChild(_link);
        this.init();
    }

    bindEvent(button) {
        if (!this.isMobile) {
            this.rectRefs = gsap.utils.toArray(".rect", button);
            button.addEventListener("pointerenter", () => this.transitionEnter());
            button.addEventListener("pointerleave", () => this.transitionLeave());
            button.addEventListener("mouseenter", () => this.transitionEnter());
            button.addEventListener("mouserleave", () => this.transitionLeave());
        }
    }
}
