import axios from "axios";
import Viewer from "./Viewer";

export default class PG_Viewer {
    constructor() {
        
        this.viewer = new Viewer();

        if (document.querySelector('.page-share') && !document.querySelector('.layer')) {
            this._loadDATA();
        }
    }


    async _loadDATA() {
        // https://blurblur.ai/viewer?type=port&model=siia-kang&index=5

        try {
            const url = new URL(window.location.href);
            const urlParams = url.searchParams;
            let type = urlParams.get("type");
            if (type == 'port') {
                type = 'portfolio';
            }
            // const name = urlParams.get("model");
            const index = urlParams.get("index");


            this.viewer.loadDATA(type, index)
 
        } catch (error) {
            window.location.href = "https://blurblur.ai/404/404.html";
        } finally {
        }
    }
}
