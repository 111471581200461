import gsap from "gsap";

export default class PG_Intro {
    constructor() {
        this.play = false;
        document.querySelector(".logo-large img").src = document.querySelector(".logo-large img").src;
        document.querySelector(".logo-large img").onload = () => {
            if (!this.play) {
                this.play = true;
                this.intro();
            }
        };

        document.querySelector(".logo-large img").onComplete = () => {
            if (!this.play) {
                this.play = true;
                this.intro();
            }
        };
        // this.intro();
    }
    intro() {
        const self = document.querySelector(".page");
        const bv = {};
        bv.blur = 0;
        // ----- logo
        gsap.set(self.querySelector(".logo-large"), { "--blur": `${bv.blur}px` });

        gsap.set("#header", { y: "-100%" });
        const dt = 0.5;

        gsap.to(bv, {
            blur: 40,
            duration: 3.0,
            ease: "power2.inOut",
            delay: dt,
            onUpdate: () => {
                gsap.set(self.querySelector(".logo-large"), { "--blur": `${bv.blur}px` });
            },
        });

        gsap.to(self, {
            autoAlpha: 0,
            duration: 1.5,
            ease: "power1.inOut",
            delay: 1.4,
            onComplete: () => {
                gsap.delayedCall(0.1, () => {
                    window.location.href = "/model";
                    history.pushState("", "", "/");
                });
            },
        });
    }
}
