import 'reset-css';
import axios from 'axios';

import gsap from 'gsap';
import ScrollTrigger from 'gsap/ScrollTrigger';
import ScrollSmoother from 'gsap/dist/ScrollSmoother';
import Swiper from 'swiper';
import { Navigation, Pagination } from 'swiper/modules';
import './style.css';
import './locomotive-scroll.css';

import 'swiper/css';
// import "swiper/css/navigation";
// import "swiper/css/pagination";

// import barba from "@barba/core";

import LocomotiveScroll from 'locomotive-scroll';
import { call } from 'file-loader';

import PG_Intro from './PG_Intro';
import PG_Index from './PG_Index';
import PG_Model from './PG_Model';
import PG_Contact from './PG_Contact';
import PG_Gallery from './PG_Gallery';
import ButtonBracket from './ButtonBracket';
import PG_Viewer from './PG_Viewer';
import PG_Theme from './PG_Theme';
import PG_Theme_detail from './PG_Theme_detail';
Swiper.use([Navigation]);
function isTouchDevice() {
    return 'ontouchstart' in window || navigator.maxTouchPoints > 0 || navigator.msMaxTouchPoints > 0;
}

const isMobile = isTouchDevice();

if (isMobile) {
    document.body.classList.add('view-mobile');
}

// class EventDispatcher {
//     constructor() {
//         this.listeners = {};
//     }

//     addListener(event, callback) {
//         if (!this.listeners[event]) {
//             this.listeners[event] = [];
//         }
//         this.listeners[event].push(callback);
//     }

//     removeListener(event, callback) {
//         if (this.listeners[event]) {
//             this.listeners[event] = this.listeners[event].filter((listener) => listener !== callback);
//         }
//     }

//     dispatch(event, data) {
//         if (this.listeners[event]) {
//             this.listeners[event].forEach((callback) => callback(data));
//         }
//     }
// }

function initWindowHeight() {
    gsap.set(document.body, { '--vh': `${window.innerHeight}px` });
}
window.addEventListener('resize', () => {
    if (!isMobile) {
        initWindowHeight();
    }
});

function resetScroll() {
    // window.history.scrollRestoration = "manual";
    // if (history.scrollRestoration) {
    //     history.scrollRestoration = "manual";
    // }
    // ScrollTrigger.clearScrollMemory("manual");
    ScrollTrigger.clearScrollMemory();
    window.onbeforeunload = function () {
        gsap.set(window, { scrollTo: { y: 0 } });
    };
    gsap.set(window, { scrollTo: { y: 0 } });
    gsap.set('.page', { scrollTo: { y: 0 } });
}

function loadPage() {
    if (document.querySelector('.page-index')) {
        new PG_Index();
        activeHeader('model');
    }
    if (document.querySelector('.page-detail')) {
        resetScroll();
        activeHeader('model');
        new PG_Model();
    }
    if (document.querySelector('.page-intro')) {
        resetScroll();
        new PG_Intro();
    }
    if (document.querySelector('.page-contact')) {
        resetScroll();
        new PG_Contact();
        activeHeader('contact');
    }
    if (document.querySelector('.page-404')) {
        resetScroll();
        new ButtonBracket({ button: document.querySelector('.button-bracket') });
    }

    if (document.querySelector('.footer')) {
        new ButtonBracket({ button: document.querySelector('.footer .button-bracket') });
    }
    if (document.querySelector('.page-share')) {
        resetScroll();
        new PG_Viewer();
    }

    if (document.querySelector('.page-theme')) {
        new PG_Theme();
        activeHeader('theme');
    }
    if (document.querySelector('.page-theme-detail')) {
        new PG_Theme_detail();
        activeHeader('theme');
    }
    if (document.querySelector('.page-gallery')) {
        new PG_Gallery();
    }

    if (window.innerWidth > 768) {
        ScrollSmoother.create({
            smooth: 0.7,
            speed: 2,
            effects: true,
            content: document.querySelector('.page'),
        });
        document.querySelector('.page').classList.add('is-smoother');
    }

    headerEvent();
}

function appearMenuLayer() {
    const layer = document.querySelector('.mb-menu-layer');
    const inner = layer.querySelector('.inner');
    const bg = layer.querySelector('.bg');
    document.body.classList.add('is-lock');
    document.body.classList.add('is-open-menu');

    gsap.set(layer, { display: 'flex' });
    gsap.to([bg], { opacity: 1, duration: 0.2, ease: 'power1.inOut' });
    gsap.to(inner, {
        opacity: 1,
        duration: 0.4,
        ease: 'power1.inOut',
        delay: 0.1,
        onComplete: () => {
            gsap.set(layer, { pointerEvents: 'auto' });
        },
    });
}
function disappearMenuLayer(callback) {
    const layer = document.querySelector('.mb-menu-layer');
    const inner = layer.querySelector('.inner');
    const bg = layer.querySelector('.bg');
    gsap.set(layer, { pointerEvents: 'none' });

    if (callback) {
        gsap.set(document.querySelector('.page'), { display: 'none' });
    } else {
    }

    gsap.to(bg, { opacity: 0, duration: 0.3, ease: 'power1.out', delay: 0.1 });
    gsap.to([inner], {
        opacity: 0,
        duration: 0.3,
        ease: 'power1.out',
        onStart: () => {},
        onComplete: () => {
            document.body.classList.remove('is-lock');
            document.body.classList.remove('is-open-menu');
        },
    });
}
function headerEvent() {
    if (document.querySelector('.button-menu-layer')) {
        document.querySelector('.button-menu-layer').addEventListener('click', () => {
            appearMenuLayer();
        });

        document.querySelector('.button-close-layer').addEventListener('click', () => {
            disappearMenuLayer();
        });
    }
}
function activeHeader(name) {
    gsap.utils.toArray('.mb-menu-layer a').forEach((a) => {
        if (a.innerHTML.toLowerCase() == name) {
            a.classList.add('select');
        } else {
            a.classList.remove('select');
        }
    });
    gsap.utils.toArray('#header .gnb-container a').forEach((a) => {
        if (a.innerHTML.toLowerCase() == name) {
            a.classList.add('select');
        } else {
            a.classList.remove('select');
        }
    });
}

//
function initFavicon() {
    function darkModeOn() {
        if (window.matchMedia && window.matchMedia('(prefers-color-scheme:dark)').matches) {
            return true;
        } else {
            return false;
        }
    }
    const isDarkMode = darkModeOn();

    const getLink = (icon) => {
        const _origin = icon.href.split('/favicons/');
        icon.href = isDarkMode ? `${_origin[0]}/favicons/${_origin[1].split('.')[0]}-dark.png` : icon.href;
    };
    document.querySelectorAll('[rel=apple-touch-icon]').forEach((icon) => getLink(icon));
    document.querySelectorAll('[rel=icon]').forEach((icon) => getLink(icon));

    document.querySelector('[name=theme-color]').content = isDarkMode ? '#000' : '#fff';
}
function init() {
    // ScrollTrigger.clearScrollMemory();

    gsap.registerPlugin(ScrollSmoother, ScrollTrigger);
    gsap.config({ nullTargetWarn: false, trialWarn: false });
    // initFavicon();
    initWindowHeight();
    loadPage();
}

window.onload = init();
