import gsap from "gsap";

export default function buttonSubmit(button) {
    const background = button.querySelector(".button-background");
    const buttonText = button.querySelector(".row");

    const isMobile = document.body.classList.contains("view-mobile");

    const transitionIn = () => {
        gsap.killTweensOf(background, "all");
        gsap.killTweensOf(buttonText, "all");
        gsap.set(background, { x: "-100%" });
        gsap.to(background, {
            x: 0,
            duration: 0.3,
            ease: "power2.out",
        });

        gsap.to(buttonText, {
            filter: "invert(1)",
            duration: 0.3,
            ease: "power1.inOut",
        });
    };
    const transitionOut = () => {
        gsap.killTweensOf(background, "all");
        gsap.killTweensOf(buttonText, "all");
        gsap.to(background, {
            x: "100%",
            duration: 0.3,
            ease: "power2.out",
        });

        gsap.to(buttonText, {
            filter: "invert(0)",
            duration: 0.3,
            ease: "power2.out",
        });
    };

    if (!isMobile) {
        button.addEventListener("mouseenter", transitionIn);
        button.addEventListener("mouseleave", transitionOut);
    }
}
