export default function Detail(props) {
    const content = document.createElement("div");
    content.classList.add("detail-wrap");
    content.classList.add("page-container");

    content.innerHTML = `
            <div class="model-main">
                <div class="model-profile">
                    <div class="row-container name-container title-name">
                        <div class="model-name-en">${props.name_en}</div>
                    </div>
                    <div class="row-container feature-container">
                        <div class="info-feature">
                            <span class="feature-key">Name</span>
                            <span class="feature-value-wrap">
                            <span class="feature-value hidden">${props.name_ko}</span>
                            <span class="feature-value"></span>
                        </span>
                        </div>
                        <div class="info-feature">
                            <span class="feature-key">Date of Birth</span>
                            <span class="feature-value-wrap">
                                <span class="feature-value hidden">${props.date_of_birth}</span>
                                <span class="feature-value"></span>
                            </span>
                        </div>
                        <div class="info-feature">
                            <span class="feature-key">Recommand</span>
                            <span class="feature-value-wrap">
                                <span class="feature-value hidden">${props.recommend_category.map((c) => c.name_en).join(", ")}</span>
                                <span class="feature-value"></span>
                            </span>
                        </div>
                        <div class="info-feature">
                            <span class="feature-key">Mood</span>
                            <span class="feature-value-wrap">
                                <span class="feature-value hidden">${props.individuality_keyword_en}</span>
                                <span class="feature-value"></span>
                            </span>
                        </div>
                    </div>
                    <div class="model-thumb">
                        <span>
                            <img alt="${props.name_en}" class="main-thumb is-pc" data-src-mo=${props.main_image_mo} data-src=${props.main_image} src=${props.main_image} />
                            <img alt="${props.name_en}" class="main-thumb is-mobile" data-src-mo=${props.main_image_mo} data-src=${props.main_image} src=${props.main_image_mo} />
                        </span>
                    </div>
                </div>
            </div>
            <div class="detail-inner">
                <div class="detail">
                    <div class="feature-accordion-container">
                        <div class="feature-accordion accordion-container">
                            <div class="accordion-button">
                                <span class="accordion-text">Profile</span>
                            </div>
                            <div class="accordion-content">
                                <div class="row">
                                    <span class="detail-key">Name.</span>
                                    <span class="detail-value share-value">${props.name_ko}</span>
                                </div>
                                <div class="row"><span class="detail-key">Birth.</span><span class="detail-value">${props.birth}</span></div>
                                <div class="row"><span class="detail-key">Place of birth.</span><span class="detail-value">${props.place_of_birth}</span></div>
                                <div class="row"><span class="detail-key">Nationality.</span><span class="detail-value">${props.nationality}</span></div>
                            </div>
                        </div>
                        <div class="feature-accordion accordion-container">
                            <div class="accordion-button">
                                <span class="accordion-text">Physical Information</span>
                            </div>
                            <div class="accordion-content">
                                <div class="row"><span class="detail-key">Height.</span><span class="detail-value">${props.height}</span></div>
                                <div class="row"><span class="detail-key">Blood type.</span><span class="detail-value">${props.blood_type}</span></div>
                                <div class="row"><span class="detail-key">Shoe size.</span><span class="detail-value">${props.shoe_size}</span></div>
                                <div class="row"><span class="detail-key">Eye sight.</span><span class="detail-value">${props.eye_sight}</span></div>
                            </div>
                        </div>
                        <div class="feature-accordion accordion-container">
                            <div class="accordion-button">
                                <span class="accordion-text">Personality</span>
                            </div>
                            <div class="accordion-content">
                                <div class="row">
                                    <span class="detail-key">Family.</span><span class="detail-value">${props.family}</span>
                                </div>
                                <div class="row">
                                    <span class="detail-key">MBTI.</span><span class="detail-value">${props.mbti}</span>
                                </div>
                                <div class="row">
                                    <span class="detail-key">Life motto.</span><span class="detail-value">${props.life_motto}</span>
                                </div>
                                <div class="row"><span class="detail-key">Constellation.</span><span class="detail-value">${props.constellation}</span></div>
                            </div>
                        </div>
                        <div class="feature-accordion accordion-container">
                            <div class="accordion-button">
                                <span class="accordion-text">Individuality</span>
                            </div>
                            <div class="accordion-content">
                                <div class="row">
                                    <span class="detail-key">Keyword.</span><span class="detail-value">${props.individuality_keyword}</span>
                                </div>
                                <div class="row">
                                    <span class="detail-key">Habit.</span><span class="detail-value">${props.habit}</span>
                                </div>
                                <div class="row">
                                    ${props.hobby ? `<span class="detail-key">Hobby.</span><span class="detail-value">${props.hobby}</span>` : ``}
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="feature-image">
                        ${props.detail_image
                            .map((image) => {
                                return `<div class="model-thumb">
                                    <img alt="${props.name_en}" class="el-image" src="${image.image}" />
                                </div>`;
                            })
                            .join("")}
                    </div>
                </div>
                ${props.interview.qnas.length > 0 ? 
                `<div class="section section-interview">
                    <div class="container">
                        <div class="title">Interview</div>
                    </div>
                    <div class="main-image">
                        <div class="model-thumb">
                            <img alt="${props.name_en}" class="is-pc" src="${props.interview.interview_main_image}" />
                            <img alt="${props.name_en}" class="is-mobile" src="${props.interview.interview_main_image_mo}" />
                        </div>
                    </div>
                    <div class="interview-container">
                        <div class="interview-column">
                            <div class="interview-qna">
                                ${props.interview.qnas
                                    .map((qna) => {
                                        return `<div class="interview el-content"><span class="interview-question">${qna.question}</span>. ${qna.answer}</div>`;
                                    })
                                    .join("")}
                            </div>
                        </div>
                        <div class="model-thumb grid-item ct-1">
                            <img alt="${props.name_en}" class="el-image center" src="${props.interview.detail_main[0].image}" />
                        </div>
                    </div>
                </div>`
                : ``
                }
                <div class="section section-use">
                        <span class="line"></span>
                        <div class="title">이용안내</div>
                        <div class="content el-contnet-custom">
                        1. 구매하신 라이선스는 원칙적으로 양도, 대여, 증여가 되지 않습니다.<br />
                        2. 회사명, 대표자명, 회사주소, 사업자 등록증 번호가 변경된 경우에는 당사의 절차에 따라서 변경사항 전달 후 사용하셔야하며 이를 준수하지 않아서
                        발생하는 문제에 대하여 당사는 책임을 지지 않습니다.<br />
                        3. 동일 계열사(자회사)라 하더라도 사업자등록번호가 동일하지 않으면 각각 회원권을 구매하셔야합니다.<br />
                        4. 콘텐츠는 CI/BI나 로고/상표/마크, 전시회/공모전 등의 용도로 사용하는 것을 금합니다. 또한 콘텐츠의 전체 또는 일부를 이용해서 만들어진 2차 저작물에
                        대하여 저작권 권리 등록을 하실 수 없습니다.<br />
                        5. 인물 컨텐츠는 외설적이거나 타인의 명예를 훼손하는 등 사회미풍양속을 저해하는 용도로 사용하는 것을 금합니다. (예: 포르노, 성인오락실, 성인용품,
                        전화방, 음란물, 성인관련사이트, 유흥업소, 숙박업소, 즉석만남, 미팅알선, 고리대금업, 마약, 도박, 사행성게임 관련, 변칙 영업,위장 마사지업, 불법,
                        퇴폐행위를 위해 건전한 것처럼 홍보, 사칭, 유인, 알선 용도, 허위사실(가명, 연령, 직업, 인터뷰, 체험 리뷰, 특정제품, 서비스 보증 등) 기재 사용,
                        비뇨기과, 성형외과, 산부인과, 비방, 중상모략, 피사체에 대한 명예훼손 및 인격권침해등 일반 정서에 반하는 용도)<br />
                        6. 온/오프라인을 통한 재배포 및 타인에게 판매, 양도, 대여, 송신(메신저/웹하드/FTP/네트워크)하는 등의 라이선스를 위한하는 행동을 금합니다.<br />
                        7. 홈페이지 내에 자료실 형태로 보관하거나 카페/블로그/SNS 등에 스크랩하는 등의 행위를 금합니다.<br />
                        8. 웹사이트/파워포인트/이메일/현수막 등의 템플릿에 사용 또는 인공지능(머신러닝, 딥러닝 등), 빅데이터의 분석, 가공을 위한 자료 등으로 사용 시 별도
                        협의가 필요합니다.
                    </div>
                </div>
                <div class="section section-related-model">
                    <div class="title el-content no-y">related Model</div>
                    <div class="feature-image el-content no-y">
                    ${props.related_models
                        .map((link) => {
                            return `<a class="model-thumb" href="/model/${link.name_en_slug}">
                            <img alt="${link.name_en}" src="${link.thumbnail}" />
                        </a>`;
                        })
                        .join("")}
                    </div>
                </div>
                <div class="footer">
                    <div class="logo-container">
                        <div class="gnb-container">
                            <a class="button-bracket" href="/contact/">
                                <span class="icon-bracket"></span><span class="rect"></span>
                                <span class="row"><span class="text">CONTACT US</span><span class="rect"></span><span class="icon-arrow"></span></span>
                                <span class="rect"></span><span class="icon-bracket reverse"></span>
                            </a>
                        </div>
                        <span class="logo"><span>blur blur</span></span>
                        <div class="gnb-container gnb-contact">
                            <span>©Blur Blur. All rights reserved.</span>
                            <div class="row-container">
                                <a class="link-privacy" href="/privacy/">Privacy</a
                                ><a href="mailto:contact@blurblur.ai" target="_blank" rel="noreferrer">contact@blurblur.ai</a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
    `;

    return content;
}
